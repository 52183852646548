


























import { PayTypeEnum } from '@/api/type/base';
import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: 'BasePayResult'
})
export default class extends Vue {
  price = '0.00';

  payType = '' as PayTypeEnum;
  payTypeText = {
    [PayTypeEnum.PAY_TYPE_WECHAT]: '微信支付',
    [PayTypeEnum.POINT_AND_WECHAT]: '微信支付'
  };

  mounted() {
    const route = this.$route;
    this.payType = route.query.payType as PayTypeEnum;
    this.price = route.query.price as string;
  }
}
